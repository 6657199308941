import { getImageUrl } from "./Util"

export const Constants = {
    ACTION_INVALID: "This action is invalid!",
    INSTAGRAM_LINK: "https://instagram.com/the_horizon_photography"
}

export const Images = {
    // logoCircle: require( "../assets/images/logoCircle.png"),
    // Service1: require("./service1.webp"),
}

export interface GalleryImage {
    src: any,
    width: number,
    height: number
}

export const GalleryImages: GalleryImage[] = [
    // {
    //     src: Images.Drashti1,
    //     width: 3,
    //     height: 4
    // },
    // {
    //     src: Images.HaleakalaSunrise,
    //     width: 4,
    //     height: 3
    // },
    // {
    //     src: Images.HaleakalaSunset,
    //     width: 4,
    //     height: 3
    // },
    // {
    //     src: Images.Seattle1,
    //     width: 3,
    //     height: 4
    // },
    // {
    //     src: Images.Seattle2,
    //     width: 3,
    //     height: 4
    // },
    // {
    //     src: Images.SeattleLibrary,
    //     width: 3,
    //     height: 4
    // },
    // {
    //     src: Images.SeattleSkyline,
    //     width: 4,
    //     height: 3
    // },
    // {
    //     src: Images.YellowstoneSunset,
    //     width: 3,
    //     height: 4
    // },
]

export interface WorksImage {
    src: any,
    title: TypeOfWorks
}

export enum TypeOfWorks {
    PORTRAIT = "PORTRAIT"
}

export const worksImages: WorksImage[] = [
    // {
    //     src: Images.Service1,
    //     title: TypeOfWorks.PORTRAIT
    // },
    // {
    //     src: Images.Service1,
    //     title: TypeOfWorks.PORTRAIT
    // }
]

export const Services: {title: string, imgSrc:any, description: []}[] = [
    // {
    //     title: "Full Photoshoot",
    //     imgSrc: Images.Service1,
    //     description: ["1 hr", "$200"]
    // }, {
    //     title: "Express Photoshoot",
    //     imgSrc: Images.Service1,
    //     description: ["1 hr", "$100"]
    // }
]