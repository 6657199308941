function Contact() {
  return (
    <div className="contact">
      <div className="serviceTitle">
        <h4>Contact Me</h4>
      </div>
    </div>
  );
}

export default Contact;
