import { Services as service } from "../Constants";
import { styled } from "../Util";

const ServicesContainer = styled("div", {
  maxWidth: "max-content",
  overflow: "hidden",
  backgroundColor: "transparent",
  width: "100%",
  margin: "auto",

  "& > ul": {
    listStyleType: "none" /* Remove bullets */,
    padding: 0 /* Remove padding */,
    margin: 0 /* Remove margins */,
    /* display: grid; */
    /* grid-template-columns: repeat(3, minmax(220px, 616px)); */
    gap: "32px",
  },
});

const ServiceItem = styled("div", {
  float: "left",
  margin: "32px",
  border: "solid 1px black",
  borderWidth: "1px",
  borderColor: "rgba(0, 0, 0, 0.2)",
  minWidth: "240px",
});

const ServiceItemBody = styled("div", {
  padding: "calc(32px - 1px) calc(32px - 1px)",
});

const ServiceItemTitle = styled("div", {
  backgroundColor: "white",
  "& > h4": {
    /* text-align: left; */
    paddingBottom: "10px",
    borderBottom: "solid 1px black",
    borderColor: "rgba(0, 0, 0, 0.2)",
  },
});

function Services() {
  return (
    <ServicesContainer>
      {/* <div className="floating-box">Floating box</div> */}

      <ul>
        {service.map((obj, index) => {
          return (
            <ServiceItem className="floating-box" key={index}>
              <div className="section">
                <img
                  src={obj.imgSrc}
                  alt="Service"
                  height="193px"
                  width="290px"
                ></img>
              </div>
              <ServiceItemBody>
                <ServiceItemTitle>
                  <h4>{obj.title}</h4>
                </ServiceItemTitle>
                <div>
                  {obj.description.map((dec, index) => {
                    return <p key={index}>{dec}</p>;
                  })}
                </div>
              </ServiceItemBody>
            </ServiceItem>
          );
        })}
      </ul>
    </ServicesContainer>
  );
}

export default Services;
