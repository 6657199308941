import { getConfigPath, getImageUrl, styled } from "../Util";
import { Chip } from "@material-ui/core";
import { ClientWork } from "../types";
import { Link, useLoaderData } from "react-router-dom";
import Isotope from "isotope-layout";
import { useEffect, useRef, useState } from "react";
import imagesloaded from "imagesloaded";
import { ISOTOPE_IDENTIFIER } from "../Portfolio/PhotosWithFilters";

const WorksContainer = styled("div", {
    paddingTop: "30px",
})

const ItemBody = styled("div", {
    // backgroundColor: "#cdd0d4",
    padding: "10%",
    textAlign: "center"
})

const Description = styled("div", {
    marginBottom: "10%",
    borderBottom: "2px solid black",
    textAlign: "center",

    "& h3": {
        fontFamily: `'Tangerine', cursive`,
        // fontSize: "48px"
    }
})

const Card = styled("div", {
    boxShadow:
        '0 2.8px 2.2px rgba(0, 0, 0, 0.034), \
  0 6.7px 5.3px rgba(0, 0, 0, 0.048), \
  0 12.5px 10px rgba(0, 0, 0, 0.06), \
  0 22.3px 17.9px rgba(0, 0, 0, 0.072), \
  0 41.8px 33.4px rgba(0, 0, 0, 0.086), \
  0 100px 80px rgba(0, 0, 0, 0.12)',
})

const Image = styled("img", {
    width: "100%",
    // borderRadius: "10px",
});

const ChipContainer = styled("div", {
    display: "inline-block", justifyContent: "center",
    // whiteSpace: "normal"
});

// amazing border layout
// https://codepen.io/giana/pen/yYBpVY

function Works() {
    const [clientWorks, setClientWorks] = useState<ClientWork[]>([]);

    useEffect(() => {
        fetch(getConfigPath("clientWork.json")).then((res) => {
            res.json().then((data: ClientWork[]) => {
                setClientWorks(data)

                // Need to load images before we use isotope
                const masonryList = document.querySelector(`.${ISOTOPE_IDENTIFIER}`);
                imagesloaded(masonryList, () => {
                    // https://isotope.metafizzy.co/layout.html
                    // https://codesandbox.io/s/react-isotope-typescript-forked-s9ir41?file=/src/App.tsx:473-480
                    isotope.current = new Isotope(".filter-content-container", {
                        itemSelector: `.${ISOTOPE_IDENTIFIER}`,
                    });
                });
            })
        });
        // cleanup
        return () => isotope.current?.destroy();
    }, [])

    clientWorks.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    // init one ref to store the future isotope object
    const isotope = useRef<Isotope | null>();

    return (
        <WorksContainer className="container-fluid">
            <div className="filter-content-container">
                <div id={ISOTOPE_IDENTIFIER} className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-lg-4 portfolio-list mb-n30 p-0">
                    {clientWorks.map((item, index) => {
                        return (
                            <div key={index} className={`col mb-70 ${ISOTOPE_IDENTIFIER}`}>
                                <Link to={`/works/${item.id}`} style={{ textDecoration: "none" }} state={{ clientWork: item }}>
                                    <Card>
                                        <Image src={getImageUrl(item.img)} />
                                        <ItemBody>
                                            <Description>
                                                <h3>{item.label}</h3>
                                                <label>{item.date}</label>
                                            </Description>
                                            <ChipContainer>
                                                {item.tags.map((tag, index) => {
                                                    return (
                                                        <Chip label={tag} key={`chip_${index}`} style={{ margin: "2px" }} />
                                                    )
                                                })}
                                            </ChipContainer>
                                        </ItemBody>
                                    </Card>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </WorksContainer>
    )
}

export default Works;