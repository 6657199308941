import { createStitches } from "@stitches/react";

const BASE_URL =
  // "https://s3.us-west-2.amazonaws.com/thehorizonphotography.com/assets/";
  "https://the-horizon-photography-resource.s3.us-west-2.amazonaws.com/";
  // "https://the-horizon-photography.s3.us-west-004.backblazeb2.com/";

export function getBaseUrl() {
  if (process.env.NODE_ENV === "production") {
    return BASE_URL;
  } else {
    return "/";
  }
}

export function getConfigPath(configName: string) {
  return getBaseUrl() + "configs/" + configName;
}

export function getImage(imageName: string) {
  return require(getImageUrl(imageName));
}

export function getImageUrl(imageName: string) {
  let imagePath = "";
  if (process.env.NODE_ENV === "production") {
    imagePath = BASE_URL + "images/" + imageName;
  } else {
    imagePath = "/images/" + imageName;
  }
  console.log(imagePath);
  return imagePath;
}

export const slugify = function (text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export const { styled, css } = createStitches({
  media: {
    xs: "(max-width: 640px)",
    s: "(max-width: 768px)",
    m: "(max-width: 1024px)",
  },
});

/*
2160p: 3840×2160
1440p: 2560×1440
1080p: 1920×1080
720p: 1280×720
480p: 854×480
360p: 640×360
240p: 426×240
*/
