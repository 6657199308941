import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Pages/Home";
import Contact from "./Components/Pages/Contact";
import Services from "./Components/Pages/Services";
import { Footer } from "./Components/Footer";
import Portfolio from "./Components/Pages/Portfolio";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
import Loader from "./Components/Loader/Loader";
import Works from "./Components/Pages/Works";
import ClientWorks from "./Components/Pages/ClientWorks";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,

      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "works",
        element: <Works />,
      },
      {
        path: "works/:id",
        element: <ClientWorks />,
      },
      {
        path: "portfolio",
        element: <Portfolio />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      // {
      //   path: "*",
      //   element: <Home />,
      // },
    ]
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]);

function App() {
  //https://reactrouter.com/en/main/components/routes
  return (
      <div className="App">
        <RouterProvider router={router} />
        <Footer />
      </div>

  );
}

function Layout() {
  return (
    // <>
    <Loader>
      <NavBar />
      <div className="body">
        <Outlet />
      </div>
    </Loader>
    // </>
  );
}

export default App;
