import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import Carousel from "react-bootstrap/Carousel";
import { CarouselProps } from "../types";
import { styled, getImageUrl } from "../Util";
// import { CarouselImages } from "./Constants";

const CarouselStyled = styled(Carousel, {
  margin: "0 auto 50px auto",
  "& > .carousel-indicators": {
    zIndex: 1,
  },
});

const CaroselContainer = styled("div", {});

const Img = styled("img", {
  objectFit: "cover",
  width: "100%",
  height: "100%",
});

const P = styled("p", {
  fontFamily: "'Tangerine', cursive",
  color: "white",
  fontWeight: "bold",
  fontSize: "48px"
})

function CarouselWithInterval(props: CarouselProps) {
  const { images = [] } = props;
  return (
    <CaroselContainer>
      <CarouselStyled>
        {images.map((image, key) => {
          return (
            <Carousel.Item key={key}>
              <AspectRatio.Root ratio={16 / 9}>
                <Img
                  className="w-100"
                  src={getImageUrl(image.src)}
                  // style={{objectFit: "cover"}}
                  alt={image.description}
                  onClick={() => {
                    return false;
                  }}
                  onContextMenu={() => {
                    return false;
                  }}
                />
              </AspectRatio.Root>
              <Carousel.Caption>
                {/* <h3>{image.description}</h3> */}
                <P>{image.description}</P>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </CarouselStyled>
    </CaroselContainer>
  );
}

export default CarouselWithInterval;
