import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Constants } from "../Constants";
import { BsInstagram } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { getImageUrl, styled } from "../Util";
//https://react-icons.github.io/react-icons

const StyledNavbar = styled(Navbar, {
  "& .nav-link > span": {
    paddingBottom: "3px",
    borderBottom: "1px solid black"
    // display: "inline-block",
    // padding: "5px 11px",
    // margin: "0 5px 15px 0",
    // border: "1px solid #9e9e9e",
    // color: "#9e9e9e",
    // background: "transparent",

    // "&:hover": {
    //   background: "#3949ab",
    //   border: "1px solid #3949ab",
    //   color: "#fff",
    //   textDecoration: "none",
    // }
  },
  // "& .nav-link.active > span": {
  //   background: "#3949ab",
  //   color: "#fff",
  // }
})

function NavBar() {
  return (
    <StyledNavbar bg="" expand="lg">
      {/* <Container> */}
      <Navbar.Brand>
        <NavLink className="nav-link" to="/">
          <img
            alt=""
            src={getImageUrl("logoCircle.png")}
            width="70"
            height="70"
            className="d-inline-block align-top"
          />
        </NavLink>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <NavLink className="nav-link" to="/">
            <span>Home</span>
          </NavLink>
          {/* <NavLink className="nav-link" to="services">
              Services
            </NavLink> */}
          <NavLink className="nav-link" to="portfolio">
            <span>Portfolio</span>
          </NavLink>
          <NavLink className="nav-link" to="works">
            <span>Client Works</span>
          </NavLink>
          <Nav.Link className="nav-link" href={Constants.INSTAGRAM_LINK}>
            <BsInstagram />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </StyledNavbar>
  );
}

export default NavBar;
