import { memo, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PhotoGalleryWithViewer from "../Portfolio/PhotoGalleryWithViewer";
import { ISOTOPE_IDENTIFIER } from "../Portfolio/PhotosWithFilters";
import { ClientWork, Photo } from "../types";
import { getConfigPath, styled } from "../Util";
import Isotope from "isotope-layout";
import imagesloaded from "imagesloaded";

export const CLIENT_WORK_PHOTO_PREFIX = "works/";

const getPhotos = (clientWork?: ClientWork): Photo[] => {
  const photos: Photo[] = [];

  if (clientWork) {
    for (let i = 0; i < clientWork.numberOfImages; i++) {
      photos.push({
        title: clientWork.label,
        imageName:
          CLIENT_WORK_PHOTO_PREFIX +
          clientWork.imagePrefix.replace(
            "*",
            (i + 1)
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })
              .toString()
          ),
        tags: clientWork.tags,
        orientation: "horizontal",
      });
    }
  }

  return photos;
};

const WorksContainer = styled("div", {
  paddingTop: "30px",
});

const ClientWorks = memo(() => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [photos, setPhotos] = useState<Photo[]>([]);

  // init one ref to store the future isotope object
  const isotope = useRef<Isotope | null>();
  useEffect(() => {
    const configPath = getConfigPath("clientWork.json");
    fetch(configPath).then((data) => {
      data.json().then((works: ClientWork[]) => {
        const item = works.find((item) => item.id === Number(id));
        if (!item) {
          navigate("/home");
        }

        setPhotos(getPhotos(item));

        const masonryList = document.querySelector(`.${ISOTOPE_IDENTIFIER}`);
        imagesloaded(masonryList, () => {
          isotope.current = new Isotope(".filter-content-container", {
            itemSelector: `.${ISOTOPE_IDENTIFIER}`,
          });
        });
      });
    });
    // cleanup
    return () => isotope.current?.destroy();
  }, []);
  return (
    <WorksContainer className="container-fluid">
      <div className="filter-content-container">
        {photos.length > 0 && <PhotoGalleryWithViewer photos={photos} />}
      </div>
    </WorksContainer>
  );
});

export default ClientWorks;

// const { clientId } = useParams();
// const location = useLocation();

// const [photos, setPhotos] = useState<Photo[]>([])
// let photos:Photo[] = [];

// useEffect(() => {

//     // Check if item is given
//     if (location.state && location.state.clientWork) {
//         const temp = getPhotos(location.state.clientWork);
//         // console.log(temp);
//         setPhotos(temp);
//     } else {
//         clientWorkLoader().then((data) => {
//             console.log("ClientWorks > clientWorkLoader >")
//             console.log(data);
//         })
//     }
// }, [])
