import CarouselWithInterval from "../Carousel/Carousel";
import { useLoaderData } from "react-router-dom";
import { getConfigPath, styled } from "../Util";
import PhotosWithFilters from "../Portfolio/PhotosWithFilters";
import { CarouselItem, Photo } from "../types";
import { useEffect, useState } from "react";

const HomeContainer = styled("div", {
  display: "block",
});

function Home() {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [carousel, setCarousel] = useState<CarouselItem[]>([]);

  useEffect(() => {
    fetch(getConfigPath("photos.json")).then((res) => {
      res.json().then((data: Photo[]) => {
        setPhotos(data);
      });
    });
    fetch(getConfigPath("carousel.json")).then((res) => {
      res.json().then((data: CarouselItem[]) => {
        setCarousel(data);
      });
    });
  }, []);

  return (
    <HomeContainer>
      {carousel.length > 0 && <CarouselWithInterval images={carousel} />}
      {photos.length > 0 && <PhotosWithFilters photos={photos} />}
    </HomeContainer>
  );
}

export default Home;
