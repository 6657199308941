import { useEffect } from "react";
import { styled } from "../Util";

const PortfolioFilterContainer = styled("div", {
  textAlign: "center",
  padding: "0 2%",
  marginBottom: "50px",
});

const Button = styled("button", {
  backgroundColor: "transparent",
  border: 0,
  borderRadius: "20px",
  position: "relative",
  cursor: "pointer",
  color: "#bdbdbd",
  textTransform: "uppercase",
  fontWeight: "600",
  lineHeight: "1.58",
  fontSize: "16px",
  display: "inline-block",
  padding: "5px 20px",

  "&:hover": {
    color: "black"
  },

  variants: {
    active: {
      true: {
        border: "2px solid black",
        color: "black",
        transition: "border-width 1s linear;"
      },
      false: {
        border: "2px solid transparent",
      }
    }
  },
  defaultVariants: {
    active: false
  }
});

const Filters = (props: {
  categories: string[];
  handleFilterKeyChange: (key: string) => () => void;
  currentFilter: string
}) => {
  const { categories, handleFilterKeyChange, currentFilter } = props;

  return (
    <PortfolioFilterContainer>
      <Button onClick={handleFilterKeyChange("*")} active={currentFilter === "*"}>
        <span>featured</span>
      </Button>
      {categories.map((cat, idx) => (
        <Button key={idx} onClick={handleFilterKeyChange(cat)} active={currentFilter === cat}>
          <span>{cat}</span>
        </Button>
      ))}
    </PortfolioFilterContainer>
  );
};

export default Filters;
