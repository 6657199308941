import React, { useState, useEffect } from "react";

const Loader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [loading]);

  //   useEffect(() => {
  //     // callback function to call when event triggers
  //     const onPageLoad = () => {
  //       console.log("page loaded");
  //       // do something else
  //         setLoading(false);
  //     };

  //     // Check if the page has already loaded
  //     if (document.readyState === "complete") {
  //       onPageLoad();
  //     } else {
  //       window.addEventListener("load", onPageLoad, false);
  //       // Remove the event listener when component unmounts
  //       return () => window.removeEventListener("load", onPageLoad);
  //     }
  //   }, []);

  return loading ? (
    <div id="loader-overlay">
      <div className="loader">
        <div className="loader-inner"></div>
      </div>
    </div>
  ) : (
    children
  );
};

export default Loader;
