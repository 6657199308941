import { getImageUrl, shuffleArray, slugify, styled } from "../Util";
/* --------- LIGHT GALLERY --------- */
// import LightGallery from 'lightgallery/react';
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
/* ---------------------------------- */
import { Photo } from "../types";
import lightGallery from "lightgallery";
import { useEffect } from "react";
import { ISOTOPE_IDENTIFIER } from "./PhotosWithFilters";
import Image from "../Image/Image";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PhotoGalleryWithViewerProps {
  photos: Photo[];
  maxWidth?: string;
  shuffle?: boolean;
}

const Image1 = styled("img", {
  width: "100%",
  borderRadius: "10px",
});

const Overlay = styled("div", {
  position: "relative",
  borderRadius: "10px",
  display: "block",
  zIndex: 1,
  "&::before": {
    position: "absolute",
    borderRadius: "10px",
    zIndex: 9,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    content: "",
    transition: "all 0.5s ease 0s",
    opacity: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    cursor: "crosshair",
  },
  "& img": {
    width: "100%",
  },
  "&:hover": {
    "&::before": {
      opacity: 0.5,
    },
  },
});

function PhotoGalleryWithViewer(props: PhotoGalleryWithViewerProps) {
  const { shuffle = false } = props;
  let photos = props.photos;
  useEffect(() => {
    const plugin = lightGallery(
      document.getElementById(ISOTOPE_IDENTIFIER) as any,
      {
        selector: `.${ISOTOPE_IDENTIFIER}`,
        plugins: [lgThumbnail, lgZoom],
      }
    );

    return function cleanup() {
      plugin.destroy();
    };
  }, []);

  if (shuffle) {
    shuffleArray(photos);
  }

  return (
    <div
      id={ISOTOPE_IDENTIFIER}
      className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-3 portfolio-list mb-n30"
    >
      {photos.map((photo, index) => {
        return (
          <div
            data-src={getImageUrl(photo.imageName)}
            key={index}
            className={`col mb-30 ${ISOTOPE_IDENTIFIER} ${photo.tags
              .map((cat) => slugify(cat))
              .join(" ")}`}
          >
            <Overlay>
              <Image1 alt={photo.title} src={getImageUrl(photo.imageName)} />
              {/* <Image
              // style={{ minWidth: "100px", height: "auto" }}
              placeholderImg="https://via.placeholder.com/150"
              src={getImageUrl(photo.imageName)}
            /> */}
              {/* <LazyLoadImage src={getImageUrl(photo.imageName)}
                minHeigt={300}
                PlaceholderSrc={"https://via.placeholder.com/150"}
                effect="blur"
              /> */}
            </Overlay>
          </div>
        );
      })}
    </div>
  );
}

export default PhotoGalleryWithViewer;
