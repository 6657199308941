import { useState, useEffect } from "react";
import PhotosWithFilters from "../Portfolio/PhotosWithFilters";
import { Photo } from "../types";
import { getConfigPath } from "../Util";

export const Portfolio: React.FC = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);

  useEffect(()=>{
    fetch(getConfigPath("photos.json")).then((res) => {
      res.json().then((data: Photo[]) => {
        setPhotos(data)
      })
    });
  },[])

  return (
    <div>
      {photos.length > 0 && <PhotosWithFilters photos={photos} />}
    </div>
  );
};

export default Portfolio;
