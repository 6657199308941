import React from "react";
import { BsInstagram } from "react-icons/bs";
import { styled } from "./Util";
import { Constants } from "./Constants";

const FooterContainer = styled("div", {
  width: "100%",
  paddingTop: "50px",
  "& > p > a": {
    textDecoration: "none",
    color: "Black",
  },
  "& > p": {
    textAlign: "center",
    fontSize: "15px",
  },
});
console.log("footer page is fully loaded");
const Footer = () => {
  return (
    <FooterContainer>
      <p>
        <a
          data-auto-recognition="true"
          href="mailto:thehorizonphotography2020@gmail.com"
        >
          thehorizonphotography2020@gmail.com
        </a>
      </p>

      <p>+1 917-496-6106</p>
      <p>
        <a href={Constants.INSTAGRAM_LINK}>
          <BsInstagram />
        </a>
      </p>
    </FooterContainer>
  );
};

export { Footer };
