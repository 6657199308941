import { useEffect, useRef, useState } from "react";

import PhotoGalleryWithViewer from "./PhotoGalleryWithViewer";
import Isotope from "isotope-layout";
import imagesLoadedMB from "imagesloaded";

import Filters from "./Filters";
import { Photo } from "../types";

export const ISOTOPE_IDENTIFIER = "isotope_identifier";

interface PhotosWithFiltersProps {
  photos: Photo[];
  shuffle?: boolean;
  hideFilters?: boolean;
}

function PhotosWithFilters(props: PhotosWithFiltersProps) {
  const { photos, shuffle = false, hideFilters = false } = props;
  const filters = () => {
    const allTags = photos.flatMap((photo) => photo.tags);
    const commonCategories = new Set(allTags);
    return Array.from(commonCategories).sort();
  };

  // init one ref to store the future isotope object
  const isotope = useRef<Isotope | null>();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");

  // initialize an Isotope object with configs
  useEffect(() => {
    // Need to load images before we use isotope
    const masonryList = document.querySelector(`.${ISOTOPE_IDENTIFIER}`);
    imagesLoadedMB(masonryList, () => {
      // https://isotope.metafizzy.co/layout.html
      // https://codesandbox.io/s/react-isotope-typescript-forked-s9ir41?file=/src/App.tsx:473-480
      isotope.current = new Isotope(".filter-content-container", {
        itemSelector: `.${ISOTOPE_IDENTIFIER}`,
        masonry: {
          columnWidth: 0
        }
      });

      isotope.current.arrange();
    });

    // cleanup
    return () => isotope.current?.destroy();
  }, []);

  // document.addEventListener("DOMContentLoaded", function(event) { 
  //   //do work
  //   const masonryList = document.querySelector(`.${ISOTOPE_IDENTIFIER}`);
  //   imagesloaded(masonryList, () => {
  //     // https://isotope.metafizzy.co/layout.html
  //     // https://codesandbox.io/s/react-isotope-typescript-forked-s9ir41?file=/src/App.tsx:473-480
  //     isotope.current = new Isotope(".filter-content-container", {
  //       itemSelector: `.${ISOTOPE_IDENTIFIER}`,
  //       layoutMode: 'fitRows'
  //     });
  //   });
  // });

  // handling filter key change
  useEffect(() => {
    if (filterKey === "*") isotope.current?.arrange({ filter: `*` });
    else isotope.current?.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key: string) => () => setFilterKey(key);

  return (
    <div className="container-fluid">
      {!hideFilters && (
        <Filters
          categories={filters()}
          handleFilterKeyChange={handleFilterKeyChange}
          currentFilter={filterKey}
        ></Filters>
      )}
      <div className="filter-content-container">
        <PhotoGalleryWithViewer photos={photos} shuffle={shuffle} />
      </div>
    </div>
  );
}

export default PhotosWithFilters;
